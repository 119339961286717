<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card card-custom card-stretch">
        <div class="card-header">
          <div class="card-title">
            {{ computedApartmentTitle }}
          </div>
          <div class="card-toolbar">
            <button type="button" class="btn btn-light-info font-weight-bold" @click="goToBooking">
              {{ $t('btn.book') }}
            </button>
          </div>
        </div>
        <div v-if="apartment" class="card-body pt-3 pb-7">
          <div class="d-flex justify-content-center">
            <img class="qr-code cursor-pointer"
                 :src="`data:image/png;base64,${apartment.qr_code}`"
                 alt=""
                 v-b-modal:qr-url-modal
            >
          </div>
          <div class="row">
            <div v-for="data in apartmentDetails" class="col-md-6">
              <div class="d-flex flex-column align-items-center text-dark-75 mt-2">
                <span class="text-muted font-weight-bolder font-size-sm">{{ $t(`label.${data}`) }}</span>
                <span class="font-weight-bolder font-size-h5 text-nowrap">
                  {{ apartment[data] }}
                </span>
              </div>
            </div>
          </div>
          <QrUrlModal :url="apartment.qr_url"/>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-custom card-stretch">
        <div class="card-body p-0 pt-5">
          <b-tabs pills content-class="pt-5" align="center" justified>
            <b-tab title-link-class="d-block text-center ml-3" :title="$t('label.services')" active>
              <ServicesCard class="p-3" :apartment-id="apartmentID"/>
            </b-tab>
            <b-tab title-link-class="d-block text-center" :title="$t('label.electricity')">
              <ElectricityCard class="p-3" :apartment-id="apartmentID"/>
            </b-tab>
            <b-tab title-link-class="d-block text-center mr-3" :title="$t('label.doors_history')">
              <DoorsHistoryCard :apartment-id="apartmentID"/>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import DoorsHistoryCard from '@/components/apartments/DoorsHistoryCard';
import ElectricityCard from '@/components/apartments/ElectricityCard';
import ServicesCard from '@/components/apartments/ServicesCard';
import QrUrlModal from "@/components/elements/modals/QrUrlModal";

export default {
  components: {
    DoorsHistoryCard,
    ElectricityCard,
    ServicesCard,
    QrUrlModal
  },
  beforeMount() {
    this.apartmentID = this.$route.params.id
    this.$store.dispatch('apartmentsStore/GET_DETAILS', this.apartmentID);
  },
  data: () => ({
    apartmentID: null,
    apartmentDetails: ['object', 'block', 'floor', 'number', 'square', 'cadastre']
  }),
  computed: {
    ...mapGetters({
      apartment: 'apartmentsStore/APARTMENT'
    }),
    computedApartmentTitle() {
      return this.apartment?.apartment || this.$t('label.apartment');
    }
  },
  methods: {
    goToBooking() {
      this.$router.push({name: 'bookingsList', query: {apartment_id: this.apartmentID}})
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code {
  height: 125px;
  width: 125px;
  background: #dcdcdc;
}
</style>